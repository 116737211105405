import React from 'react';
import Layout from '../components/Layout/Layout';
import WhyIterativeMain from '../components/WhyIterativeMain';
import Header from '../components/Header/Header';
import PageTitle from '../components/PageTitle/PageTitle';
import Footer from '../components/Footer/Footer';

function WhyIterative() {
  return (
    <Layout title="Why Iterative">
      <Header>
        <PageTitle
          title="Why Iterative"
          text="Move fast and collaborate across your ML team.
          Our mission is to deliver the best developer experience for machine learning teams by creating an ecosystem of open, modular ML tools."
        />
      </Header>
      <WhyIterativeMain />
      <Footer />
    </Layout>
  );
}

export default WhyIterative;
