import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import SectionTitle from '../../SectionTitle/SectionTitle';
import Title from '../../Title/Title';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import * as styles from './index.module.scss';

export default function Section({
  text,
  title,
  image,
  imageAlt,
  sections,
  theme,
}) {
  return (
    <section className={styles.section}>
      <div className={styles.section__hero}>
        <div className={styles.section__heroText}>
          <SectionTitle
            sectionLineTheme={theme}
            className={styles.section__title}
            title={title}
          />
          {getImage(image) ? (
            <div className={cn(styles.section__img, 'md:hidden')}>
              <GatsbyImage alt={imageAlt} image={getImage(image)} />
            </div>
          ) : (
            <img
              className={cn(styles.section__img, 'md:hidden')}
              alt={imageAlt}
              src={image}
              width="400"
              height="300"
            />
          )}
          <p className={styles.section__text}>{text}</p>
        </div>
        {getImage(image) ? (
          <div className={cn(styles.section__img, 'hidden md:inline-block')}>
            <GatsbyImage alt={imageAlt} image={getImage(image)} />
          </div>
        ) : (
          <img
            className={cn(styles.section__img, 'hidden md:inline-block')}
            alt={imageAlt}
            src={image}
            width="400"
            height="300"
          />
        )}
      </div>
      <div className={styles.section__subsection}>
        {sections.map(({ title: subtitle, text: subtext }, i) => (
          <div key={i} className={styles.subsection}>
            <Title
              className={styles.subsection__title}
              size="s"
              title={subtitle}
            />
            <p className={styles.subsection__text}>{subtext}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

Section.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  imageAlt: PropTypes.string,
  theme: PropTypes.oneOf(['orange', 'blue']),
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
};
