const content = [
  {
    title: 'Developer-first Approach',
    image: 'developerFirst',
    imageAlt:
      'Three squares next to each other, representing a terminal, a code block, and a workflow.',
    text: "Our products couple tightly with existing software development tools and processes. We're laser-focused in building tools that improve the experience and workflows of ML engineers and data scientists. ",
    sections: [
      {
        title: 'Aligned with DevOps',
        text: "Iterative's ML tools are GitOps-based, matching with software teams using Git as the source of truth for all applications. Information and versioning around ML models and experiments, data, artifacts, and hyperparameters, are all stored within an organization's Git service. This GitOps approach aligns the ML model development lifecycle with that of your apps and services so you get faster time-to-market with transparent collaboration between your software development and ML teams.",
      },
      {
        title: 'Better experience, better models',
        text: 'Our open source tools are all command line-oriented and have integrations with IDEs like Visual Studio Code so engineers can see their code and experiments in the same place locally, with fast feedback loops. Using familiar toolsets like Git, CLI, and integrating with development environments means a more productive and effective team.',
      },
      {
        title: 'Workflow automation',
        text: "We believe in eliminating infrastructure concerns and management, whether it's in the cloud or on-premises.  Iterative tools abstract away the complexity of managing data and compute resources like automating CI/CD workflows so ML teams can focus on model building. ML developers should be able to focus on developing models, not learn how to set up compute resources that are secure and performant.",
      },
    ],
  },
  {
    title: 'Data-centric AI Focus',
    image: 'dataCentric',
    imageAlt:
      'Small cubes coming through multiple colorful rings with a larger cube as their destination.',
    text: 'In every step of the ML model development lifecycle, Iterative emphasizes managing data used for training and testing as the most important component.',
    sections: [
      {
        title: 'Better data, better models',
        text: 'Iterative ties data together with models and experiments so ML teams can get a better handle on how training data affects their models. See your data next to your model code as well as store and manage that data to incorporate into the software development process. Our approach enables data-centric AI and ensures your models use consistent, quality data.',
      },
      {
        title: 'Simplifying unstructured data',
        text: "We're data structure-agnostic but have a specific focus on unstructured data management. There aren't as many tools for managing unstructured data (images, audio files, videos, etc.) and machine learning models grow in complexity with this type of data. We're enabling better workflows so that teams can properly manage unstructured data.",
      },
    ],
  },
  {
    title: 'Open, Modular Tools',
    image: 'openModular',
    imageAlt: 'Four puzzle pieces connecting together',
    text: 'Iterative tools are built to play nicely with all the other tools in your ML technology stack. By offering an open platform, we allow you to integrate Iterative tools with your current MLOps solutions and processes without having to be locked into a specific vendor.',
    sections: [
      {
        title: 'Modularity baked-in',
        text: "Augment and improve your MLOps tech stack in a modular manner - add in Iterative tools where it makes sense. We're compatible with all the tools your teams use, like your Git service (GitHub, Gitlab, BitBucket), CI/CD solution, and more. Our modularity isn't just APIs (which can add to complexity!) - we're modular by design. This means it's easier to automate workflows. Your data science team can build these workflows on traditional tools like Git and CI/CD solutions, and not have to rely on custom tools with kludgy scripts.",
      },
      {
        title: 'Any language or cloud',
        text: "We're language- and cloud-agnostic so your ML teams can freely use various tools across the ML ecosystem to meet your organization's specific needs and requirements. Use Python, R, or C++ to build your models and store data or train models using any cloud (AWS, Azure, GCP, kubernetes, or even on-premises).",
      },
    ],
  },
];

export default content;
