import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import RequestDemo from '../RequestDemo';
import content from './text';
import * as styles from './index.module.scss';
import Section from './Section';

export default function WhyIterativeMain() {
  const images = useStaticQuery(query);

  return (
    <main>
      {content.map(({ title, text, sections, image, imageAlt = '' }, i) => (
        <Section
          key={i}
          theme={i % 2 === 0 ? 'blue' : 'orange'}
          title={title}
          image={images[image]}
          text={text}
          imageAlt={imageAlt}
          sections={sections}
        />
      ))}
      <RequestDemo buttonProps={{ className: styles.typeform }} />
    </main>
  );
}

const query = graphql`
  query getWhyIterativeImages {
    developerFirst: file(
      relativePath: { regex: "/why-iterative/developer-first.png$/" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 480
          placeholder: BLURRED
          quality: 90
        )
      }
    }
    dataCentric: file(
      relativePath: { regex: "/why-iterative/data-centric.png$/" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 480
          placeholder: BLURRED
          quality: 90
        )
      }
    }
    openModular: file(
      relativePath: { regex: "/why-iterative/open-modular.png$/" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 480
          placeholder: BLURRED
          quality: 90
        )
      }
    }
  }
`;
